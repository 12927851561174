import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image'
import moment from 'moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import de from 'moment/locale/de';

moment.locale('de', de);

const BlogPostTemplate = ({ data, pageContext }) => {
	const { fields, html } = data.markdownRemark;
	let { title, image, date, type, youtubeid } = data.markdownRemark.frontmatter;
	// console.log(pageContext)
	const types = {
		'youtube': 'video',
		'twitter': 'image',
		'instagram': 'image'
	};
	return (
		<Layout>
			<SEO title={title} image={image ? image.publicURL : undefined} keywords={[]} />
			<span itemScope itemType="http://schema.org/Article">
				<section id="article">
					<article className={types[type]}>
						<div className="ribbon" />
						<header>
							<h2>
								<Link to={fields.slug}>{title}</Link>
							</h2>
							<p>{moment.utc(date).format('DD. MMMM YYYY')}</p>
						</header>
						<hr />
						{html ? <section dangerouslySetInnerHTML={{ __html: html }} /> : null}
						{image ? <Img fluid={image.childImageSharp.fluid} /> : null}
						{youtubeid && (
							<section>
								<div class="embed">
									<iframe title={youtubeid} src={`https://www.youtube.com/embed/${youtubeid}?rel=0&amp;showinfo=0&amp;autoplay=1`}  frameborder="0" allowfullscreen></iframe>
								</div>
							</section>
						)}
					</article>
				</section>
			</span>
		</Layout>
	);
};
export default BlogPostTemplate;

export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				date
				path
				title
				image {
					id
					name
					ext
					relativePath
					publicURL
					childImageSharp {
						fluid(maxWidth: 910, quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}
				type
				url
				youtubeid
			}
			fields {
				slug
			}
		}
	}
`;
